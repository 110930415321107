exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parcel-content-js": () => import("./../../../src/pages/parcel-content.js" /* webpackChunkName: "component---src-pages-parcel-content-js" */),
  "component---src-pages-payment-error-js": () => import("./../../../src/pages/payment-error.js" /* webpackChunkName: "component---src-pages-payment-error-js" */),
  "component---src-pages-payments-js": () => import("./../../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-restore-js": () => import("./../../../src/pages/restore.js" /* webpackChunkName: "component---src-pages-restore-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-successful-payment-js": () => import("./../../../src/pages/successful-payment.js" /* webpackChunkName: "component---src-pages-successful-payment-js" */)
}

